import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { closeModal } from './alertDialogSlice';
import {useSelector} from "react-redux";

export default function AlertDialog() {
  const open = useSelector(state => state.alertDialog.open);
  const title = useSelector(state => state.alertDialog.title);
  const content = useSelector(state => state.alertDialog.content);
  const buttons = useSelector(state => state.alertDialog.buttons);

  let jsxContent = content;
  if (typeof content === 'string') {
    jsxContent = <DialogContentText id="alert-dialog-description">{content}</DialogContentText>;
  }

  return (
    <Dialog
      open={open}
      onClose={closeModal}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {jsxContent}
      </DialogContent>
      <DialogActions>
        {buttons}
      </DialogActions>
    </Dialog>
  );
}
