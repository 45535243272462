import React  from 'react';
import Grid from '@mui/material/Grid';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Input from '@mui/material/Input';
import InputAdornment from "@mui/material/InputAdornment";
import Paper from '@mui/material/Paper';
import CircularProgress from '@mui/material/CircularProgress';
import AddIcon from "@mui/icons-material/Add";
import { useSelector, useDispatch } from 'react-redux';
import {
  updateNewProduct,
  addProduct,
  removeProduct,
  duplicateProduct,
  updateNewDuplicateProduct
} from './productListSlice';
import { openModal, closeModal } from '../../alert/alertDialogSlice';
import ProductListRow from "./ProductListRow";

const styles = {
  table: {
    minWidth: 650,
  },
};

export default function ProductList() {
  const newProduct = useSelector(state => state.products.newProduct);
  const products = useSelector(state => state.products.items);
  const loading = useSelector(state => state.products.loading);
  const dispatch = useDispatch();

  if (loading.list) {
    return <CircularProgress/>;
  }

  const handleFormSubmit = e => {
    e.preventDefault();
    dispatch(addProduct());
  };

  const deleteProduct = product => {
    dispatch(openModal({
      title: `Supprimer ${product.name} ?`,
      content: `Êtes-vous sûr de vouloir supprimer ${product.name} ?`,
      buttons: [
        <Button key='cancel' onClick={() => dispatch(closeModal())} color="primary">Annuler</Button>,
        <Button key='delete' onClick={() => dispatch(removeProduct(product.slug))} color="error">Supprimer</Button>,
      ]
    }));
  };

  const copyProduct = product => {
    dispatch(openModal({
      title: `Dupliquer ${product.name} ?`,
      content: (
        <React.Fragment>
          <p>
            Quel nom souhaitez-vous donner à la copie de <strong>{product.name}</strong> ?
          </p>
          <Input placeholder="Nom du produit"
                 onChange={e => dispatch(updateNewDuplicateProduct({prop: 'name', value: e.target.value}))}
                 fullWidth
          />
        </React.Fragment>
      ),
      buttons: [
        <Button key='cancel' onClick={() => dispatch(closeModal())} color="primary">Annuler</Button>,
        <Button key='duplicate' onClick={() => dispatch(duplicateProduct(product))} color="primary">Dupliquer</Button>,
      ]
    }));
  }

  const rows = products.map(p => <ProductListRow key={p.id} product={p} loading={loading} onDelete={() => deleteProduct(p)} onDuplicate={() => copyProduct(p)}/>);

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <Grid container alignItems="center" spacing={5}>
          <Grid item>
            <TextField
              value={newProduct.name}
              onChange={e => dispatch(updateNewProduct({ prop: 'name', value: e.target.value }))}
              label="Nom"
              margin="normal"
              required
            />
          </Grid>
          <Grid item>
            <TextField
              value={newProduct.price}
              onChange={e => dispatch(updateNewProduct({ prop: 'price', value: e.target.value }))}
              type="number"
              label="Prix"
              margin="normal"
              required
              InputProps={{
                endAdornment: <InputAdornment position="start">€</InputAdornment>
              }}
            />
          </Grid>
          <Grid item>
            <Button type="submit" variant="contained" color="primary" disabled={loading.form} startIcon={loading.form ? <CircularProgress size={14}/> : <AddIcon/>}>
              Ajouter
            </Button>
          </Grid>
        </Grid>
      </form>
      <TableContainer component={Paper}>
        <Table sx={styles.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nom</TableCell>
              <TableCell>Prix</TableCell>
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
