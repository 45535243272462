import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import { enqueueSnackbar } from '../../../appSlice';
import { closeModal } from '../../alert/alertDialogSlice';
import ApiService from '../../../app/services/api.service';

export const fetchProducts = createAsyncThunk(
  'products/fetch',
  async () => await ApiService.get('products')
);

export const addProduct = createAsyncThunk(
  'products/add',
  async (arg, { getState, dispatch }) => {
    return await ApiService.post('products', getState().products.newProduct, {}, {
      201: () => dispatch(enqueueSnackbar({ message: 'Produit ajouté avec succès !', options: {variant: 'success'} }))
    });
  }
);

export const removeProduct = createAsyncThunk(
  'products/remove',
  async (productSlug, { dispatch }) => {
    await ApiService.delete('products', productSlug, {}, {
      204: () => {
        dispatch(enqueueSnackbar({ message: 'Produit supprimé avec succès !', options: {variant: 'success'} }));
        dispatch(closeModal());
      }
    });
    return productSlug;
  }
);

export const duplicateProduct = createAsyncThunk(
  'products/duplicate',
  async (p, { getState, dispatch }) => {
    return await ApiService.post('products', {name: getState().products.newDuplicateProduct.name, price: p.price, highlights: p.highlights.map(h => h['@id']), versions: []}, {}, {
      201: async (response) => {
        let newProduct = response.data;
        dispatch(closeModal());

        dispatch(enqueueSnackbar({ message: 'Récupération des données du produit...', options: {variant: 'info'} }));
        const product = await ApiService.get('products', p.slug);

        dispatch(enqueueSnackbar({ message: 'Duplication des options...', options: {variant: 'info'} }));
        let promises = [];
        let oldToNewFeatureMapping = new Map();
        let newToOldFeatureMapping = new Map();
        for (const feature of product.features) {
          promises.push(
            ApiService.post('features', {
              ...feature,
              id: undefined,
              '@id': undefined,
              product: newProduct['@id'],
              category: feature.category['@id'],
              images: feature.images.map(i => i['@id']),
              incompatibleFeatures: [],
              requiredFeatures: []
            }).then(data => {
              oldToNewFeatureMapping.set(feature['@id'], data['@id']); // Map old feature id to new feature id
              newToOldFeatureMapping.set(data['@id'], feature['@id']); // Map new feature id to old feature id
            })
          );
        }
        await Promise.all(promises).then(() => {
          dispatch(enqueueSnackbar({ message: 'Duplication des options terminée !', options: {variant: 'success'} }));
        });

        // Mise à jour du produit pour récupérer les nouvelles options
        newProduct = await ApiService.get('products', newProduct.slug);

        dispatch(enqueueSnackbar({ message: 'Mise à jour des dépendances entre les options...', options: {variant: 'info'} }));
        promises = [];
        for (const feature of newProduct.features) {
          const oldProductFeature = product.features.find(f => f['@id'] === newToOldFeatureMapping.get(feature['@id']));
          promises.push(
            ApiService.patch('features', feature.id, {
              incompatibleFeatures: oldProductFeature.incompatibleFeatures.map(f => oldToNewFeatureMapping.get(f['@id'])),
              requiredFeatures: oldProductFeature.requiredFeatures.map(f => oldToNewFeatureMapping.get(f['@id']))
            })
          );
        }
        await Promise.all(promises).then(() => {
          dispatch(enqueueSnackbar({ message: 'Mise à jour des dépendances terminée !', options: {variant: 'success'} }));
        });

        dispatch(enqueueSnackbar({ message: 'Duplication des versions...', options: {variant: 'info'} }));
        promises = [];
        for (const versionId of product.versions.map(v => v.id)) {
          const version = await ApiService.get('versions', versionId);
          promises.push(
            ApiService.post('versions', {
              ...version,
              id: undefined,
              '@id': undefined,
              product: newProduct['@id'],
              image: version?.image['@id'],
              features: version.features.map(vf => ({
                ...vf,
                id: undefined,
                '@id': undefined,
                version: undefined,
                feature: oldToNewFeatureMapping.get(vf.feature['@id']),
                images: vf.images.map(i => i['@id'])
                }))
            })
          );
        }
        await Promise.all(promises).then(() => {
          dispatch(enqueueSnackbar({ message: 'Duplication des versions terminée !', options: {variant: 'success'} }));
        });

        dispatch(enqueueSnackbar({ message: 'Produit dupliqué avec succès !', options: {variant: 'success'} }));
      }
    });
  }
);

const initialState = {
  loading: {
    list: false,
    form: false,
    product: null
  },
  newProduct: {
    name: '',
    price: ''
  },
  newDuplicateProduct: {
    name: ''
  },
  items: []
};

export const productListSlice = createSlice({
  name: 'productList',
  initialState: initialState,
  reducers: {
    updateNewProduct: (state, action) => {
      state.newProduct[action.payload.prop] = action.payload.value;
    },
    updateNewDuplicateProduct: (state, action) => {
      state.newDuplicateProduct[action.payload.prop] = action.payload.value;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchProducts.pending, (state) => {
      state.loading.list = true;
    });
    builder.addCase(fetchProducts.fulfilled, (state, action) => {
      state.items = action.payload;
      state.loading.list = false;
    });
    builder.addCase(fetchProducts.rejected, (state) => {
      state.loading.list = false;
    });
    builder.addCase(addProduct.pending, (state) => {
      state.loading.form = true;
    });
    builder.addCase(addProduct.fulfilled, (state, action) => {
      state.items.unshift(action.payload);
      state.newProduct = initialState.newProduct;
      state.loading.form = false;
    });
    builder.addCase(addProduct.rejected, (state) => {
      state.loading.form = false;
    });
    builder.addCase(duplicateProduct.pending, (state) => {
      state.loading.list = true;
    });
    builder.addCase(duplicateProduct.fulfilled, (state, action) => {
      state.items.unshift(action.payload);
      state.newDuplicateProduct = initialState.newDuplicateProduct;
      state.loading.list = false;
    });
    builder.addCase(duplicateProduct.rejected, (state) => {
      state.loading.list = false;
    });
    builder.addCase(removeProduct.pending, (state, action) => {
      state.loading.product = action.meta.arg;
    });
    builder.addCase(removeProduct.fulfilled, (state, action) => {
      state.items = state.items.filter(i => i.slug !== action.payload);
      state.loading.product = null;
    });
    builder.addCase(removeProduct.rejected, (state, error) => {
      state.loading.product = null;
    });
  },
});

export const { updateNewProduct, updateNewDuplicateProduct } = productListSlice.actions;

export default productListSlice.reducer;
