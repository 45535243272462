import React from "react";
import {Link} from "react-router-dom";
import TableCell from "@mui/material/TableCell";
import IconButton from "@mui/material/IconButton";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CopyIcon from "@mui/icons-material/ContentCopy";
import TableRow from "@mui/material/TableRow";

export default function ProductListRow({ product, loading, onDelete, onDuplicate }) {

  function handleDuplicate(e, product) {
    e.preventDefault();
    onDuplicate(product);
  }

  function handleDelete(e, product) {
    e.preventDefault();
    onDelete(product);
  }

  return (
    <TableRow key={product.id}>
      <TableCell scope="row">{product.name}</TableCell>
      <TableCell scope="row">{new Intl.NumberFormat('fr-FR', { style: 'currency', currency: 'EUR' }).format(product.price)}</TableCell>
      <TableCell>
        <Link to={"/products/" + product.slug}>
          <IconButton color="primary" aria-label="Modifier">
            <EditIcon/>
          </IconButton>
        </Link>
        <IconButton onClick={handleDuplicate} disabled={loading.product === product.id} color="primary" aria-label="Dupliquer">
          <CopyIcon/>
        </IconButton>
        <IconButton onClick={handleDelete} disabled={loading.product === product.id} color="error" aria-label="Supprimer">
          <DeleteIcon/>
        </IconButton>
      </TableCell>
    </TableRow>
  );
}
